<script setup>
  import { defineProps } from 'vue'
  import { event } from 'vue-gtag'

  import { useOpenPreorder } from '@/composables/useOpenPreorder'

  // Todo: pinia loader buy
  const props = defineProps({
    preorderText: String,
    isActive: {
      type: Boolean,
      default: false,
    },
  })

  function clickPreorder() {
    if (!props.isActive) return

    event('preorder_click', {
      category: 'Call-To-Action',
      action: 'click',
      value: 'click',
      label: 'Click For Preorder',
    })

    useOpenPreorder()
  }
</script>

<template>
  <a
    href="javascript:void(0);"
    :class="{ disabled: !props.isActive }"
    @click="clickPreorder()">
    {{ props.preorderText }}
  </a>
</template>

<style scoped lang="scss">
  .button {
    &:disabled,
    &[disabled],
    &.disabled {
      opacity: 0.5;
    }
  }

  .button.disabled:hover {
    cursor: not-allowed;
    color: #ffffff;
    background: #ff88eb;
    padding: 1em 0;
    border: none;
  }
</style>
