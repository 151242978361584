<script setup>
  import { computed, defineProps, reactive, ref } from 'vue'
  import { event } from 'vue-gtag'

  import { useOpenPurchase } from '@/composables/useOpenPurchase'
  import { salesStatuses, salesStatusLabels } from '@/enums/salesStatuses'
  import { useProductStore } from "@/stores/products";

  // Todo: fix global sales status preorder for products
  // Todo: pinia loader buy

  const props = defineProps({
    productId: Number,
    productSalesStatus: String,
    pageSalesStatus: String,
    purchaseText: String,
    gtmValue: String,
    discolorHover: {
      type: String,
      default: '#ffffff',
    },
    disbgcolorHover: {
      type: String,
      default: '#ff88eb',
    },
    preorderLink: {
      type: String,
      default: null,
    },
  })

  const productStore = useProductStore()

  const isOpenPage = computed(() => props.pageSalesStatus === salesStatuses.open)

  const isOpenProduct = computed(() => props.productSalesStatus === salesStatuses.open)

  // Todo: треба визначити хто головний сторінка чи продукт
  const isPreorderActive = computed(
    () =>
      (props.productSalesStatus === salesStatuses.preorder || props.pageSalesStatus === salesStatuses.preorder) &&
      props.preorderLink !== null,
  )

  // Todo: fix
  // props.productSalesStatus,
  const salesStatus = computed(() =>
    isOpenPage.value && isOpenProduct.value ? salesStatuses.open : props.pageSalesStatus,
  )

  const buttonPurchaseText = computed(() => salesStatusLabels[salesStatus.value])
  const isDisabled = computed(() => !isOpenPage.value || !isOpenProduct.value)
  const isHover = ref(false)
  const isShowDisabledStyles = computed(() => isDisabled.value && isHover.value)

  function setHover(value) {
    isHover.value = value
  }

  function clickPurchase(id) {
    if (isDisabled.value) return

    if (salesStatus.value === salesStatuses.open || salesStatus.value === salesStatuses.preorder) {
      const valEvent = props.gtmValue ? props.gtmValue : `product_id_${props.productId}`
      event('click_buy', {
        category: 'Call-To-Action',
        action: 'click',
        // Todo: add price
        value: 0,
        label: `Click For Buy ${valEvent}`,
        campaign_id: `${productStore.activeCampaignId}`,
        page_id: `${productStore.pageId}`,
        product_id: `${id}`,
      })

      useOpenPurchase(id)
    } else {
      alert('Продажі ще не відкриті')
    }
  }

  function clickPreorder() {
    const valEvent = props.gtmValue ? props.gtmValue : `product_id_${props.productId}`
    event('click_preorder', {
      category: 'Call-To-Action',
      action: 'click',
      // Todo: add price
      value: 0,
      label: `Click For Preorder ${valEvent}`,
    })
  }
</script>

<template>
  <a
    v-if="!isPreorderActive"
    :style="{
      backgroundColor: isShowDisabledStyles ? disbgcolorHover : '',
      color: isShowDisabledStyles ? discolorHover : '',
    }"
    href="javascript:void(0);"
    :class="{ disabled: isDisabled }"
    @mouseover="setHover(true)"
    @mouseleave="setHover(false)"
    @click="clickPurchase(props.productId)">
    {{ purchaseText ? purchaseText : buttonPurchaseText }}
  </a>
  <a
    v-else
    :style="{
      backgroundColor: isShowDisabledStyles ? disbgcolorHover : '',
      color: isShowDisabledStyles ? discolorHover : '',
    }"
    :href="props.preorderLink"
    @mouseover="setHover(true)"
    @mouseleave="setHover(false)"
    @click="clickPreorder(props.productId)">
    {{ purchaseText ? purchaseText : buttonPurchaseText }}
  </a>
</template>

<style scoped lang="scss">
  .button {
    &:disabled,
    &[disabled],
    &.disabled {
      opacity: 0.5;
    }
  }

  .button.disabled:hover {
    cursor: not-allowed;
    padding: 1em 0;
    border: none;
  }
</style>
