<script setup>
  import { onClickOutside } from '@vueuse/core'
  import { ref } from 'vue'

  import CartItem from '@/components/Cart/CartItem.vue'
  import CloseIcon from '@/components/Icons/CloseIcon.vue'
  import { useCartStore } from '@/stores/cart'
  import { useGeneralStore } from '@/stores/general'

  import PurchaseForm from '../Forms/PurchaseForm.vue'

  const props = defineProps({
    pageId: Number,
    activeCampaignId: Number,
    hidePromocode: Boolean,
    color: {
      type: String,
      default: '#8444F6',
    },
  })
  const emit = defineEmits(['afterCreatedOrder'])

  const generalStore = useGeneralStore()
  const cartStore = useCartStore()

  const target = ref(null)

  onClickOutside(target, () => generalStore.closeModal())

  function emitHandler(data) {
    emit('afterCreatedOrder', data)
  }
</script>

<template>
  <div
    class="cart__main"
    :class="{ show_modal: generalStore.isModalOpen }">
    <div
      ref="target"
      class="cart-popup">
      <div class="cart-header"> Ваше замовлення: </div>
      <div class="cart__close">
        <CloseIcon @click="generalStore.closeModal()" />
      </div>
      <div class="cart-items">
        <CartItem
          v-for="item in cartStore.formattedCart"
          :item="item"
          :color="props.color" />
      </div>

      <PurchaseForm
        :hide-promocode="props.hidePromocode"
        :color="props.color"
        :page-id="props.pageId"
        :active-campaign-id="props.activeCampaignId"
        @after-created-order="emitHandler" />
    </div>
  </div>
</template>

<style lang="scss">
  .cart__close {
    position: absolute;
    right: 10px;
    top: 10px;
    bottom: 0;
  }

  .cart__main {
    color: #ededed;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 16777001;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .show_modal {
    padding: 0 15px;
    display: flex;
  }

  .cart-popup {
    width: 100%;
    max-width: 500px;
    padding: 15px 30px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 9999;
    max-height: 90vh;
    overflow-y: auto;
  }

  .cart-header {
    position: relative;
    text-align: center;
    padding: 8px 0;
    padding-bottom: 16px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #353535;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  .cart-items {
    margin-bottom: 15px;
  }

  .cart-item {
    padding-top: 10px;
    padding-bottom: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;

      span {
        display: block;
      }
    }
  }

  .cart-total {
    padding-top: 15px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: flex-end;

    &--label {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      color: #171717;
    }

    &--value {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: #171717;
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-end;
      gap: 10px;
    }
  }

  .cart-group {
    position: relative;

    & + .cart-group {
      margin-top: 10px;
    }
  }

  .cart-payment--title {
    margin-top: 50px;
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 43px;
    color: #353535;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin-bottom: 30px;
  }

  .cart-payments {
    margin-top: 15px !important;
    display: flex;
    align-items: center;
    gap: 10px;
    list-style: none !important;
  }

  .cart-submit {
    margin-top: 17px;
  }

  .cart-input {
    width: 100%;
    height: 35px;
    border: 1px solid rgba(53, 53, 53, 0.5);
    box-sizing: border-box;
    padding: 25px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: rgba(53, 53, 53, 0.5);
    border-radius: 15px;
    font-family: Montserrat, sans-serif;
  }

  .cart-group--promo.active .cart-input--promocode {
    border: 1px solid #171717;
  }

  .cart-group--promo.active .cart-promo--check {
    display: flex;
    position: absolute;
    right: 5px;
    top: 5px;
    height: 48px;
    bottom: inherit;
    background: #171717;
    border: none;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
  }

  .hidden {
    display: none;
  }

  .cart-checkbox {
  }

  .cart-promo--check {
    position: absolute;
    font-size: 18px;
    line-height: 22px;
    right: 0;
    height: 52px;
    padding: 0 25px;
    border-radius: 15px;
    color: white;
    border: 0;
  }

  .cart-promo--check:hover {
    cursor: pointer;
    background-color: #580be1;
  }

  .cart-promo--check:disabled {
    background-color: rgba(53, 53, 53, 0.5);
  }

  .label-checkbox {
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    cursor: pointer;
  }

  .label-checkbox p,
  .label-checkbox p a {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    color: #353535;
    opacity: 0.5;
  }

  .label-checkbox input:checked ~ p {
    opacity: 1;
  }

  .label-checkbox:hover input:not(:checked) + .checkbox-indicator {
    opacity: 1;
    border: 2px solid #353535;
  }

  .label-checkbox:hover input:not(:checked) + .checkbox-indicator path {
    stroke: #353535;
  }

  .label-checkbox p a {
    text-decoration: underline;
    opacity: 1;
  }

  .checkbox-indicator {
    width: 20px;
    height: 20px;
    min-width: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #353535;
    border-radius: 4px;
    opacity: 0.5;
  }

  .radio-indicator {
    height: 36px;
    font-size: 20px;
    border: 1px solid rgba(53, 53, 53, 0.1);
    border-radius: 10px;
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: rgba(53, 53, 53, 0.5);
  }

  @media (max-width: 460px) {
    .radio-indicator {
      font-size: 16px;
    }
  }

  @media (max-width: 360px) {
    .radio-indicator {
      font-size: 14px;
    }
  }

  @media (max-width: 320px) {
    .radio-indicator {
      font-size: 12px;
    }
  }

  .radio-indicator--info {
    margin-top: 5px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #353535;
    opacity: 0.5;
  }

  input:checked + .radio-indicator {
    border: 1px solid #171717;
    font-style: 600;
    color: #fff;
  }

  .label-checkbox input:checked + .checkbox-indicator {
    opacity: 1;
    background: #171717;
    border-color: #171717;
  }

  .cart-payments li {
    width: 100%;
  }

  .cart-payment--label {
    cursor: pointer;
  }

  .cart-payment--label input {
    display: none;
  }

  .cart-submit--btn {
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    border: none;
    border-radius: 15px;

    &:disabled {
      background: rgba(53, 53, 53, 0.5);
    }
  }
</style>
