<script setup>
  import { onClickOutside } from '@vueuse/core'
  import { defineProps, reactive, ref } from 'vue'

  import CloseIcon from '@/components/Icons/CloseIcon.vue'
  import { useGeneralStore } from '@/stores/general'

  const props = defineProps({
    name: {
      type: String,
      default: 'HOTME',
    },
    color: {
      type: String,
      default: '#ededed',
    },
    titleColor: {
      type: String,
      default: '#FDEFF4',
    },
    textColor: {
      type: String,
      default: '#fdeff4',
    },
    bgColor: {
      type: String,
      default: '#1c132a',
    },
    bgAccentColor: {
      type: String,
      default: 'linear-gradient(180deg, #ff8b8b 0%, #a700b0 100%)',
    },
  })

  const stylePopup = reactive({
    color: props.color,
  })

  const stylePopupThanks = reactive({
    backgroundColor: props.bgColor,
  })

  const styleAccentName = reactive({
    background: props.bgAccentColor,
    backgroundClip: 'text',
  })

  const styleTitle = reactive({
    color: props.titleColor,
  })

  const styleDescription = reactive({
    color: props.textColor,
  })

  const styleButton = reactive({
    background: props.bgAccentColor,
  })

  const generalStore = useGeneralStore()
  const target = ref(null)

  onClickOutside(target, () => generalStore.closeThanksModal())
</script>

<template>
  <div
    class="popup"
    :class="{ show_modal: generalStore.isThanksModalOpen }"
    :style="stylePopup">
    <div
      ref="target"
      class="popup-thanks"
      :style="stylePopupThanks">
      <div class="popup-close">
        <CloseIcon @click="generalStore.closeThanksModal()" />
      </div>

      <div class="popup-main">
        <div class="form-block">
          <h1
            class="title"
            :style="styleTitle">
            Передзапис на марафон <span :style="styleAccentName">{{ props.name }}</span> успішно виконаний
          </h1>
          <p :style="styleDescription">Ми обовʼязково сповістимо про старт нового потоку.</p>
          <!-- Todo: add gtm event -->
          <a
            v-if="generalStore.thanksModalBonusUrl !== null"
            :style="styleButton"
            :href="generalStore.thanksModalBonusUrl"
            class="cart-submit--btn">
            Отримати бонус
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .popup-close {
    position: absolute;
    right: 10px;
    top: 10px;
    bottom: 0;
  }

  .popup {
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 16777001;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .show_modal {
    padding: 0 15px;
    display: flex;
  }

  .popup-thanks {
    width: 100%;
    max-width: 500px;
    min-height: 400px;
    padding: 15px 30px;
    border-radius: 10px;
    box-shadow: 0px 5px 25px rgba(255, 255, 255, 0.25);
    position: relative;
    z-index: 9999;
    max-height: 90vh;
    overflow-y: auto;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hidden {
    display: none;
  }

  .form-block .title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 80px;
  }

  .form-block h1 {
    text-align: center;
  }

  .form-block h1 span {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .form-block p {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }

  .cart-submit--btn {
    margin-top: 30px;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1.02px;
  }
</style>
