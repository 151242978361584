<script setup>

</script>

<template>
  <div class="circle">
    <div class="before"></div>
    <div class="after"></div>
  </div>
</template>

<style scoped>
  .circle {
    width: 25px;
    height: 25px;
    position: relative;
    border-radius: 50%;
    border: 2px solid #666;
    cursor: pointer;
    z-index: 2;
  }
  .before, .after {
    content: '';
    position: absolute;
    width: 13px;
    height: 1.5px;
    background-color: #666;
    border-radius: 0;
    top: 10px;
    z-index: 0;
    transform: rotate(-45deg);
  }
  .before {
    left: 4px;
  }
  .after {
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    transform: rotate(-135deg);
    right: 4px;
  }

</style>
