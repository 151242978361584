<script setup>
  import { computed, onMounted, ref } from 'vue'

  import { useCartStore } from '@/stores/cart'

  const props = defineProps(['modelValue'])
  const emit = defineEmits(['update:modelValue', 'isValidated'])
  const cartStore = useCartStore()

  const telInputRef = ref()

  const isValid = computed({
    get() {
      return cartStore.phoneIsValid
    },
    set(val) {
      cartStore.setPhoneIsValid(val)
    },
  })

  const value = computed({
    get() {
      return props.modelValue
    },
    set(val) {
      emit('update:modelValue', val)
    },
  })

  const inputRef = ref(null)
  const valueInput = computed({
    get() {
      return inputRef.value
    },
    set(val) {
      inputRef.value = val
      emit('update:modelValue', val)
    },
  })

  const inputOptions = {
    placeholder: 'Номер телефону:',
    required: true,
    styleClasses: 'cart-input cart-input-phone',
    validCharactersOnly: true,
    invalidMsg: 'Невірний номер телефону',
    showDialCode: true,
    type: 'tel',
  }

  function input(val, obj) {
    // console.log('input', val, obj)
  }

  function validate(obj) {
    console.log('validate', obj)
    isValid.value = typeof obj.valid === 'undefined' ? null : obj.valid
    emit('isValidated', isValid.value)
  }

  onMounted(() => {
    inputRef.value = props.modelValue
  })
</script>

<template>
  <div
    class="cart-group"
    :class="{ invalid: isValid === false }">
    <div class="cart-label">
      <vue-tel-input
        ref="telInputRef"
        v-model="valueInput"
        class="cart-input-sphone"
        :input-options="inputOptions"
        @input="input"
        @validate="validate"></vue-tel-input>
    </div>
    <div class="invalid-feedback">Невірний номер телефону</div>
  </div>
</template>

<style>
  .invalid-feedback {
    display: none;
  }

  .cart-group,
  .shake {
    &.invalid {
      .cart-input-sphone,
      .vti__dropdown,
      input.cart-input {
        border-color: #f84147 !important;
      }

      .cart-input {
        color: #f84147 !important;
      }

      .invalid-feedback {
        display: block;
        color: #f84147;
      }
    }
  }

  .cart-input-sphone,
  .cart-input-phone {
    border: 1px solid rgba(53, 53, 53, 0.5) !important;
    border-radius: 15px !important;
  }

  .cart-input-phone {
    padding-left: 10px !important;
  }

  .vti__input {
    border: 0 !important;
    border-left: 1px solid rgba(53, 53, 53, 0.5) !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .vti__dropdown {
    border-radius: 15px;
    padding-left: 10px !important;
  }

  .vti__dropdown-list li {
    color: rgba(53, 53, 53, 0.85);
  }

  .shake {
    animation: horizontal-shaking 0.3s infinite;
  }

  @keyframes horizontal-shaking {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(5px);
    }
    50% {
      transform: translateX(-5px);
    }
    75% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }
</style>
