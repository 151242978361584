import { defineStore } from "pinia";
import preorder from "@/api/preorder";


export const useGeneralStore = defineStore('general', {
    id: 'general',
    state: () => ({
        isModalOpen: false,
        isModalLoading: false,

        isPreOrderModalOpen: false,
        isPreOrderModalLoading: false,

        isThanksModalOpen: false,

        isLeaveModalOpen: false,
        thanksModalBonusUrl: null,
    }),
    persist: false,
    actions: {
        openModal() {
            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
        },
        startModalLoading() {
            this.isModalLoading = true;
        },
        stopModalLoading() {
            this.isModalLoading = false;
        },

        openPreOrderModal() {
          this.isPreOrderModalOpen = true;
        },
        closePreOrderModal() {
          this.isPreOrderModalOpen = false;
        },
        startPreOrderModalLoading() {
          this.isPreOrderModalLoading = true;
        },
        stopPreOrderModalLoading() {
          this.isPreOrderModalLoading = false;
        },

        openThanksModal(bonusUrl = null) {
          this.isThanksModalOpen = true;
          this.isModalOpen = false;
          this.isPreOrderModalOpen = false;
          this.thanksModalBonusUrl = bonusUrl;
        },
        closeThanksModal() {
          this.isThanksModalOpen = false;
        },

        openLeaveModal() {
          this.isLeaveModalOpen = true;
        },

        closeLeaveModal() {
          this.isLeaveModalOpen = false;
        },

        async preorder(form) {
          return await preorder.create(form);
        },
    },
})
