<script setup>
  import { defineModel } from 'vue'

  const model = defineModel()
</script>

<template>
  <div class="cart-group">
    <label class="cart-label">
      <input
        v-model="model"
        name="usr_email"
        class="cart-input cart-input--email"
        placeholder="E-mail"
        type="email"
        required="" />
    </label>
  </div>
</template>

<style scoped></style>
