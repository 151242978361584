<script setup>
  import { defineModel } from 'vue'

  const model = defineModel()
</script>

<template>
  <div class="cart-checkbox">
    <label class="label-checkbox">
      <input
        v-model="model"
        type="checkbox"
        name="agree_terms"
        class="hidden cart-checkbox--field" />
      <span class="checkbox-indicator">
        <svg
          width="11"
          height="10"
          viewBox="0 0 11 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 4L4.5 7.5L9.5 1"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"></path>
        </svg>
      </span>
      <p>
        Натискаючи на кнопку, ви даєте згоду на обробку персональних даних та погоджуєтесь з
        <a
          href="/privacy"
          target="_blank"
          >політикою конфіденційності</a
        >
      </p>
    </label>
  </div>
</template>

<style scoped>
  .cart-checkbox {
    padding-top: 15px;
    margin-top: 15px;
  }
</style>
