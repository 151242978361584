import apiClient from '@/config/apiClient'

export default {
  create(data) {
    return apiClient.post('/order-simple/create', data)
  },

  applyPromocode(data) {
    return apiClient.post('/order-simple/promocode-apply', data)
  },

  applyCertificate(data) {
    return apiClient.post('/order-simple/certificate-apply', data)
  },
}
