<script setup>
  import { computed, onMounted } from 'vue'

  import currencies from '@/enums/currencies'
  import { paymentMethods } from '@/enums/paymentMethods'
  import { useCartStore } from '@/stores/cart'

  const props = defineProps({
    modelValue: String,
    hideCertificate: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#8444F6',
    },
  })

  const emit = defineEmits(['update:modelValue'])

  const value = computed({
    get() {
      return props.modelValue
    },
    set(val) {
      emit('update:modelValue', val)
    },
  })
  const cartStore = useCartStore()

  function changeMethod() {
    cartStore.setCurrency(null)
    cartStore.setPaymentMethod(paymentMethods.certificate)
  }

  function isChecked(paymentMethod) {
    return cartStore.paymentMethod === paymentMethod
  }

  onMounted(() => {
    // by default
    // cartStore.setPaymentMethod(paymentMethods.bank)
  })
</script>

<template>
  <ul class="cart-payments methods">
    <li class="cart-payment">
      <label class="cart-payment--label">
        <input
          v-model="value"
          type="radio"
          name="paymentMethods"
          :value="paymentMethods.certificate"
          :checked="isChecked(paymentMethods.certificate)"
          @change="changeMethod" />
        <p
          :style="{ 'background-color': isChecked(paymentMethods.certificate) ? props.color : 'white' }"
          class="radio-indicator"
          >Оплата сертифікатом</p
        >
      </label>
    </li>
  </ul>
</template>

<style scoped></style>
