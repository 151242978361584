<script setup>
  import { defineModel } from 'vue'

  const model = defineModel()
</script>

<template>
  <div class="cart-group">
    <label class="cart-label">
      <input
        v-model="model"
        name="usr_name"
        type="text"
        class="cart-input"
        placeholder="Ім'я"
        required="" />
    </label>
  </div>
</template>

<style scoped></style>
