<script setup>
  import { computed, onMounted } from 'vue'

  import currencies from '@/enums/currencies'
  import { paymentMethods } from '@/enums/paymentMethods'
  import { useCartStore } from '@/stores/cart'

  const props = defineProps({
    color: {
      type: String,
      default: '#8444F6',
    },
  })

  const cartStore = useCartStore()

  function changeCurrency(val) {
    cartStore.setCurrency(val)
    cartStore.setPaymentMethod(paymentMethods.bank)
  }

  const isCheckedUah = computed(() => cartStore.currency === currencies.uah)
  const isCheckedEur = computed(() => cartStore.currency === currencies.eur)

  onMounted(() => {})
</script>

<template>
  <ul class="cart-payments">
    <li class="cart-payment">
      <label class="cart-payment--label">
        <input
          type="radio"
          :value="currencies.uah"
          name="currency_type"
          :checked="isCheckedUah"
          @change="changeCurrency($event.target.value)" />

        <p
          :style="{ 'background-color': isCheckedUah ? props.color : 'white' }"
          class="radio-indicator"
          >Оплата в грн</p
        >
      </label>
    </li>
    <li class="cart-payment">
      <label class="cart-payment--label">
        <input
          type="radio"
          name="currency_type"
          :value="currencies.eur"
          :checked="isCheckedEur"
          @change="changeCurrency($event.target.value)" />
        <p
          :style="{ 'background-color': isCheckedEur ? props.color : 'white' }"
          class="radio-indicator"
          >Оплата в євро</p
        >
      </label>
    </li>
  </ul>
</template>

<style scoped></style>
