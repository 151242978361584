<script setup>
  import CloseCircleIcon from '@/components/Icons/CloseCircleIcon.vue'
  import { useCartStore } from '@/stores/cart'
  import { useGeneralStore } from '@/stores/general'

  const props = defineProps({
    item: Object,
    color: {
      type: String,
      default: '#8444F6',
    },
  })

  const generalStore = useGeneralStore()
  const cartStore = useCartStore()

  function removeInCart() {
    cartStore.remove(props.item.id)

    if (cartStore.count === 0) {
      generalStore.closeModal()
    }
  }
</script>

<template>
  <div class="cart-item">
    <p :style="{ color: props.color }"
      ><span :style="{ color: props.color }">{{ item.showName }}</span></p
    >
    <a
      href="javascript:void(0)"
      class="cart-delete"
      @click="removeInCart">
      <CloseCircleIcon />
    </a>
  </div>
</template>

<style lang="scss">
  .cart-delete {
    display: flex;
  }
</style>
