import { defineStore } from 'pinia'

export const useProductStore = defineStore({
    id: 'products',

    state: () => ({
        items: {},
        ids: [],
        // todo: move to other store
        pageId: null,
        activeCampaignId: null,
    }),
    persist: false,
    getters: {
        list() {
            return this.ids.map(i => this.items[i])
        },

        loaded() {
            return this.ids.length > 0
        },
    },

    actions: {
        setProducts(products) {
            if (this.loaded)
                return

            this.ids = products.map((product) => {
                if (product !== null) {
                  this.items[product.id] = product
                  return product.id
                }
            })
        },

        setPageId(id) {
            this.pageId = id
        },

        setActiveCampaignId(id) {
            this.activeCampaignId = id
        }
    },
})
