<script setup>
  import { ref } from 'vue'

  const formRef = ref()

  const props = defineProps({
    data: {
      type: Object,
    },
  })
</script>

<template>
  <form
    ref="formRef"
    autocomplete="off"
    method="post"
    action="https://secure.wayforpay.com/pay"
    accept-charset="utf-8"
    style="width: 1px; height: 1px">
    <input
      type="hidden"
      name="merchantAccount"
      :value="props.data.merchantAccount" />
    <input
      type="hidden"
      name="merchantAuthType"
      :value="props.data.merchantAuthType" />
    <input
      type="hidden"
      name="merchantDomainName"
      :value="props.data.merchantDomainName" />
    <input
      type="hidden"
      name="merchantSignature"
      :value="props.data.merchantSignature" />
    <input
      type="hidden"
      name="orderReference"
      :value="props.data.orderReference" />
    <input
      type="hidden"
      name="orderDate"
      :value="props.data.orderDate" />
    <input
      type="hidden"
      name="amount"
      :value="props.data.amount" />
    <input
      type="hidden"
      name="currency"
      :value="props.data.currency" />
    <div v-for="product in props.data.products">
      <input
        type="hidden"
        name="productName[]"
        :value="product.name" />
      <input
        type="hidden"
        name="productPrice[]"
        :value="product.amount" />
      <input
        type="hidden"
        name="productCount[]"
        :value="product.count" />
    </div>

    <input
      v-if="props.data.hasOwnProperty('regularMode') && props.data.regularMode != null"
      type="hidden"
      name="regularMode"
      :value="props.data.regularMode" />
    <input
      v-if="props.data.hasOwnProperty('regularAmount') && props.data.regularAmount != null"
      type="hidden"
      name="regularAmount"
      :value="props.data.regularAmount" />
    <input
      v-if="props.data.hasOwnProperty('regularCount') && props.data.regularCount != null"
      type="hidden"
      name="regularCount"
      :value="props.data.regularCount" />
    <input
      v-if="props.data.hasOwnProperty('regularBehavior') && props.data.regularBehavior != null"
      type="hidden"
      name="regularBehavior"
      :value="props.data.regularBehavior" />
    <input
      v-if="props.data.hasOwnProperty('regularOn') && props.data.regularOn != null"
      type="hidden"
      name="regularOn"
      :value="props.data.regularOn" />

    <input
      type="hidden"
      name="clientFirstName"
      :value="props.data.clientFirstName" />
    <input
      type="hidden"
      name="clientEmail"
      :value="props.data.clientEmail" />
    <input
      type="hidden"
      name="defaultPaymentSystem"
      :value="props.data.defaultPaymentSystem" />
    <input
      type="hidden"
      name="serviceUrl"
      :value="props.data.serviceUrl" />
    <input
      type="hidden"
      name="returnUrl"
      :value="props.data.returnUrl" />
    <input
      type="hidden"
      name="approvedUrl"
      :value="props.data.returnUrl" />
  </form>
</template>
