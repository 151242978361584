<script setup>
  import { vMaska } from 'maska'
  import { computed, ref, watch } from 'vue'

  import { useCartStore } from '@/stores/cart'

  const cartStore = useCartStore()

  const props = defineProps({
    modelValue: String,
    campaignId: Number,
    color: {
      type: String,
      default: '#8444F6',
    },
  })
  const emit = defineEmits(['update:modelValue'])
  const certificateError = computed({
    get() {
      return cartStore.certificate.error
    },
    set(val) {
      cartStore.setCertificateError(val)
    },
  })

  const certificateActivated = computed({
    get() {
      return cartStore.certificate.activated
    },
    set(val) {
      cartStore.setCertificateActivated(val)
    },
  })

  const value = computed({
    get() {
      return props.modelValue
    },
    set(val) {
      certificateError.value = false
      emit('update:modelValue', val)
    },
  })

  watch(value, (oldValue, newValue) => {
    if (!newValue) {
      certificateError.value = false
      certificateActivated.value = false
    }
  })

  const isNotEmptyValue = computed(() => props.modelValue?.length > 0)

  function applyCertificate() {
    cartStore
      .applyCertificate(value.value, cartStore.formattedCart[0].id, props.campaignId)
      .then((response) => {
        certificateError.value = false
        certificateActivated.value = true

        cartStore.setApplyCertificate()
      })
      .catch((error) => {
        console.log(error)
        certificateError.value = true
      })
  }
</script>

<template>
  <!-- Todo: circle activated -->
  <div class="cart-group cart-group--certificate">
    <label class="cart-label">
      <input
        v-model="value"
        v-maska
        data-maska="***-***"
        :disabled="certificateActivated"
        required
        name="usr_certificateCode"
        type="text"
        class="cart-input cart-input--promocode"
        placeholder="Сертифікат" />
    </label>
    <button
      :disabled="certificateActivated"
      type="button"
      class="cart-promo--check"
      :style="{ 'background-color': props.color }"
      :class="{ hidden: !isNotEmptyValue }"
      @click="applyCertificate">
      Активувати
    </button>
    <div
      class="promo-success"
      :class="{ hidden: !certificateActivated }">
      <span class="promo-txt"
        >Сертифікат <b>{{ value }}</b> Активовано</span
      >
    </div>
    <div
      class="promo-error"
      :class="{ hidden: !certificateError }">
      <span class="promo-txt">Невірний сертифікат</span>
    </div>
    <div class="certificate__tooltip"
         v-show="!cartStore.certificate.isApply"
         :style="{ 'color': props.color }">Введи сертифікат вище ☝️</div>
  </div>
</template>

<style scoped>
  .cart-group--certificate {
    margin-top: 15px;
  }

  .promo-success {
    margin-top: 10px;
    margin-left: 5px;
    color: green;
  }

  .promo-error {
    margin-top: 10px;
    margin-left: 5px;
    color: red;
  }

  .certificate__tooltip {
    font-weight: bold;
    margin: 10px 5px 5px;
  }
</style>
