export const salesStatuses = {
      'open': 'open',
      'closed': 'closed',
      'preorder': 'preorder',
      'sold_out': 'sold_out',
  }


export const salesStatusLabels = {
  'open': 'Придбати',
  'closed': 'Продажі зачинено',
  'preorder': 'Предзамовлення',
  'sold_out': 'Розпродано',
}
