import { defineStore } from 'pinia'

export const useCartCookieFieldsStore = defineStore('cart-cookie-fields', {
    persist: {
      storage: localStorage,
    },
    state: () => ({
        name: null,
        phone: null,
        email: null,
    }),

    getters: {
        isExist() {
            return this.name !== null && this.phone !== null && this.email !== null
        },
    },

    actions: {
        reset() {
            this.name = null;
            this.phone = null;
            this.email = null;
        },
        set(name, phone, email) {
            this.name = name;
            this.phone = phone;
            this.email = email;
        },
    },
})
