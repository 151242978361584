<script setup>
  import { computed, onBeforeUnmount, ref } from 'vue'

  import { useCartStore } from '@/stores/cart'

  const cartStore = useCartStore()

  const props = defineProps({
    modelValue: String,
    color: {
      type: String,
      default: '#8444F6',
    },
  })
  const emit = defineEmits(['update:modelValue'])
  const promoError = computed({
    get() {
      return cartStore.promocode.error
    },
    set(val) {
      cartStore.setPromoCodeError(val)
    },
  })

  const promoActivated = computed({
    get() {
      return cartStore.promocode.activated
    },
    set(val) {
      cartStore.setPromoCodeActivated(val)
    },
  })

  const value = computed({
    get() {
      return props.modelValue
    },
    set(val) {
      promoError.value = false
      emit('update:modelValue', val)
    },
  })

  const isNotEmptyValue = computed(() => props.modelValue?.length > 0)

  onBeforeUnmount(() => {
    // promoError.value = false
    // cartStore.resetPromocode()
    // emit('update:modelValue', null)
  })

  function applyPromocode() {
    cartStore
      .applyPromocode(value.value, cartStore.formattedCart[0].id)
      .then((response) => {
        promoError.value = false
        promoActivated.value = true

        cartStore.setApplyPromocode(response.data.discountPriceEur, response.data.discountPriceUah)
      })
      .catch((error) => {
        console.log(error)
        promoError.value = true
      })
  }
</script>

<template>
  <!-- Todo: circle activated -->
  <div class="cart-group cart-group--promo">
    <label class="cart-label">
      <input
        v-model="value"
        :disabled="promoActivated"
        name="usr_promocode"
        type="text"
        class="cart-input cart-input--promocode"
        placeholder="Промокод" />
    </label>
    <button
      :disabled="promoActivated"
      type="button"
      class="cart-promo--check"
      :style="{ 'background-color': props.color }"
      :class="{ hidden: !isNotEmptyValue }"
      @click="applyPromocode">
      Активувати
    </button>
    <div
      class="promo-success"
      :class="{ hidden: !promoActivated }">
      <span class="promo-txt"
        >Промокод <b>{{ value }}</b> Активовано</span
      >
    </div>
    <div
      class="promo-error"
      :class="{ hidden: !promoError }">
      <span class="promo-txt">Невірний промокод</span>
    </div>
  </div>
</template>

<style scoped>
  .promo-success {
    margin-top: 10px;
    color: green;
  }

  .promo-error {
    margin-top: 10px;
    color: red;
  }
</style>
