import { onUnmounted } from 'vue'
import { useGeneralStore } from "@/stores/general.js";
import { useCartStore } from '@/stores/cart.js'


export function useOpenPurchase(productId)
{
    const generalStore = useGeneralStore();
    const cartStore = useCartStore();

    // Todo: check when callback
    cartStore.resetCertificate()
    cartStore.resetCurrency()
    cartStore.resetPaymentMethod()
    cartStore.resetPromocode()
    cartStore.resetPhoneIsValid()
    cartStore.one(productId)
    generalStore.openModal()
}
