<script setup>
  import { computed, onMounted } from 'vue'

  import { offers } from '@/enums/offers'
  import { useCartStore } from '@/stores/cart'

  const props = defineProps({
    color: {
      type: String,
      default: '#8444F6',
    },
  })

  const cartStore = useCartStore()

  function changeOffer(val) {
    cartStore.setOfferType(val)
  }

  const isCheckedSpecialOffer = computed(() => cartStore.offerType === offers.special)

  onMounted(() => {})
</script>

<template>
  <div class="cart-special-wrapper">
    <ul class="cart-special-offer">
      <li class="cart-special-offer--item">
        <label class="cart-payment--label">
          <input
            type="radio"
            :value="offers.simple"
            name="offer_type"
            :checked="!isCheckedSpecialOffer"
            @change="changeOffer($event.target.value)" />

          <p
            :style="{ 'background-color': !isCheckedSpecialOffer ? props.color : 'white' }"
            class="radio-indicator radio-indicator--first"
            >{{ cartStore.specialOfferInfo?.noSpecialOfferLabel }}</p
          >
        </label>
      </li>
      <li class="cart-special-offer--item">
        <label class="cart-payment--label">
          <input
            type="radio"
            name="offer_type"
            :value="offers.special"
            :checked="isCheckedSpecialOffer"
            @change="changeOffer($event.target.value)" />
          <p
            :style="{ 'background-color': isCheckedSpecialOffer ? props.color : 'white' }"
            class="radio-indicator radio-indicator--last"
            >{{ cartStore.specialOfferInfo?.specialOfferLabel }}
            <span
              v-show="cartStore.specialOfferInfo?.specialOfferDiscountLabel > 0"
              class="cart-special-offer-discount--label"
              >- {{ cartStore.specialOfferInfo?.specialOfferDiscountLabel }}%</span
            ></p
          >
        </label>
      </li>
    </ul>
    <div
      v-if="isCheckedSpecialOffer && cartStore.specialOfferInfo?.specialOfferDescription !== null"
      class="cart-special-offer--description"
      :style="{ 'border-color': props.color }"
      v-html="cartStore.specialOfferInfo?.specialOfferDescription">
    </div>
  </div>
</template>

<style scoped lang="scss">
  .cart-special {
    &-offer {
      display: inline-flex;
      margin: 0 0 15px 0;
      &--item {
        position: relative;
      }
      &--description {
        font-size: 12px;
        background-color: #eee;
        color: black;
        padding: 10px 15px;
        border: 1px dashed #333;
        border-radius: 10px;
        margin: -5px 0 10px;
      }
      .cart-payment--label {
        .radio-indicator {
          padding: 0 25px;
          &--first {
            border-radius: 10px 0 0 10px;
          }
          &--last {
            border-radius: 0 10px 10px 0;
          }
        }
      }
      &-discount {
        &--label {
          position: absolute;
          font-size: 10px;
          font-weight: bold;
          color: #fff;
          background-color: #f84147;
          padding: 4px 8px;
          right: -20px;
          top: -5px;
          border-radius: 10px;
          box-shadow: 0.5px 0.5px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
</style>

<style lang="scss">
  .cart-special {
    &-offer {
      &--description {
        p {
          font-weight: bold;

          span {
            color: #f84147;
          }

          ul {
            font-weight: normal;

            li {
              padding: 1px 0;
            }
          }
        }
      }
    }
  }
</style>
