<script setup>
  import { nextTick, onMounted, ref } from 'vue'

  import ModalCart from '@/components/Cart/ModalCart.vue'
  import ModalLoading from '@/components/Cart/ModalLoading.vue'
  import WayForPayCheckoutForm from '@/components/Forms/WFPCheckoutForm.vue'
  import { paymentMethods } from '@/enums/paymentMethods'
  import { useCartStore } from '@/stores/cart'
  import { useGeneralStore } from '@/stores/general'
  import { useProductStore } from '@/stores/products'

  const props = defineProps({
    pageId: Number,
    activeCampaignId: Number,
    color: String,
    products: Array,
  })

  const wayForPayPurchaseData = ref({})
  const isActiveWayForPayForm = ref(false)
  const wfpCheckoutForm = ref()

  const generalStore = useGeneralStore()
  const cartStore = useCartStore()
  const keyModal = ref(1)

  cartStore.resetCertificate()

  function submitForm() {
    wfpCheckoutForm.value.$refs.formRef.submit()
  }

  async function purchaseController(emitProps) {
    if (emitProps.paymentProvider === paymentMethods.bank) {
      isActiveWayForPayForm.value = true
      wayForPayPurchaseData.value = emitProps.data
      generalStore.closeModal()
      // generalStore.startModalLoading()
      await nextTick()
      // generalStore.stopModalLoading()
      submitForm()
    }

    // Checkout by certificate
    if (emitProps.paymentProvider === paymentMethods.certificate) {
      // redirect to success page
      window.location.href = emitProps.data.url
    }
  }

  const productStore = useProductStore()
  productStore.setProducts(props.products)
  productStore.setActiveCampaignId(props.activeCampaignId)
  productStore.setPageId(props.pageId)

  onMounted(() => {
    keyModal.value += 1

    document.addEventListener('visibilitychange', () => {
      // fix bug with modal back in browser
      // todo: reset all states and sensasitive data
      // todo: check on open modal and run rerender
      // generalStore.closeModal()
      keyModal.value += 1
    })
  })
</script>

<template>
  <ModalCart
    :key="keyModal"
    v-if="generalStore.isModalOpen"
    :color="props.color"
    :page-id="props.pageId"
    :active-campaign-id="props.activeCampaignId"
    @after-created-order="purchaseController" />

  <ModalLoading
    v-if="generalStore.isModalLoading"
    :color="props.color" />

  <WayForPayCheckoutForm
    v-show="isActiveWayForPayForm"
    ref="wfpCheckoutForm"
    :data="wayForPayPurchaseData" />
</template>

<style lang="scss"></style>
