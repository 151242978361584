<script setup>
  import MoonLoader from 'vue-spinner/src/MoonLoader.vue'

  import { useGeneralStore } from '@/stores/general'

  const props = defineProps({
    color: String,
  })

  const generalStore = useGeneralStore()
</script>

<template>
  <div
    class="modal"
    :class="{ show_modal: generalStore.isModalLoading }">
    <div
      ref="target"
      class="modal__popup">
      <div class="loader">
        <MoonLoader
          class="loader__spinner"
          size="60px"
          :loading="generalStore.isModalLoading"
          :color="props.color" />
        <span
          class="loader__text"
          :style="{ color: props.color }"
          >Зачекайте...</span
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .modal {
    color: #ededed;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 16777001;
    background-color: rgba(0, 0, 0, 0.6);

    &__popup {
      width: 100%;
      min-width: 240px;
      min-height: 240px;
      max-width: 240px;
      max-height: 240px;
      padding: 15px 30px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      position: relative;
      z-index: 9999;
      overflow-y: auto;
      text-align: center;

      // loader
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .show_modal {
    padding: 0 15px;
    display: flex;
  }

  .loader {
    &__spinner {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    &__text {
      text-align: center;
      font-size: 18px;
    }
  }
</style>
